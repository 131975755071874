'use client'
import Script from 'next/script'
import { useEffect, useState } from 'react';
export function Scripts(): JSX.Element {
    const [displyScripts, setDisplyScripts] = useState(false);
    useEffect(() => {
        setTimeout(() => { setDisplyScripts(true) }, 10000)
    }, [displyScripts]);
    if (displyScripts) {
        return <>
            <Script strategy="lazyOnload" src="https://own.wmweb.agency/im_livechat/loader/2" />
            <Script strategy="lazyOnload" src="https://own.wmweb.agency/im_livechat/assets_embed.js" />

        </>
    } else {
        return <></>
    }

}